import styles from "./ChordChordBanner.module.scss";

export default function ChordChordBanner() {
  return (
    <section className={styles.container}>
      <div className={styles.banner}>
        {/* <img
          src="/chord-chord-left.svg"
          alt=""
          className={styles.imgLeft}
        /> */}
        <div className={styles.body}>
          <h2 className={styles.title}>
            Learn to Play <b>50 Songs</b> With
            <br />
            <span>
              <span>
                <b>Only 4 Chords</b>
              </span>
            </span>
          </h2>
          <img
            src="/chordchord-center-pdf.png"
            alt=""
            className={styles.imgCenter}
          />
          <a
            href="https://4chords.get.chordchord.com?utm_source=guitar-tuner"
            className={styles.btn}
            target="_blank"
          >
            <span>GET FREE PDF</span>
          </a>
          {/* <div className={styles.name}>ChordChord</div> */}
        </div>
      </div>
    </section>
  );
}
